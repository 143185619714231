import Vue from "@/helper/initVue";

import { TextImageSlider } from "@/features/text-image-slider";

const elements = document.querySelectorAll(".text-image-slider");

Array.from(elements).forEach((element) => {
  try {
    const records = element.getAttribute("data-records");
    let parsedRecords = {};
    if (records) {
      parsedRecords = JSON.parse(records);
    }
    if (element) {
      new Vue({
        el: element,
        render: (h) =>
          h(TextImageSlider, {
            props: {
              records: parsedRecords,
            },
          }),
      });
    }
  } catch (err) {
    console.log("Error");
  }
});
