


























































































import { VueAgile } from "vue-agile";
import { defineComponent, computed, PropType } from "@vue/composition-api";
import { TextImageSliderCaptionsType } from "./TextImageSliderCaptionsType.types";

export default defineComponent({
  name: "TextImageSlider",
  components: {
    agile: VueAgile,
  },
  props: {
    records: {
      type: Object as PropType<TextImageSliderCaptionsType>,
    },
  },
  setup(props) {
    const captions = computed(() => props.records);
    const getButtonLink = (path: string) => {
      return path.startsWith("/")
        ? path
        : path.indexOf("://") == -1
        ? "https://" + path
        : path;
    };
    return {
      captions,
      getButtonLink,
    };
  },
});
